import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import { withSite } from '../../contexts/SiteContext';
import { useUrlGenerator } from '@folklore/routes';

import Ad from '../partials/Ad';
import More from '../buttons/More';
import PageMeta from '../meta/PageMeta';
import ArticlesGrid from '../lists/ArticlesGrid';

import styles from '../../../styles/pages/home-universities.module.scss';

const propTypes = {
    site: AppPropTypes.site.isRequired,
    page: AppPropTypes.page.isRequired,
    isDisabled: PropTypes.bool,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    isDisabled: false,
    onMetadataChange: null,
};

function HomeUniversitiesPage({ site, page, isDisabled, onMetadataChange }) {
    const { sections = [] } = site;
    const { latestArticles = [] } = page;
    const generateUrl = useUrlGenerator();
    const articlesSection = sections.find(it => it.id === 'articles') || null;
    return (
        <main className={styles.container}>
            {!isDisabled ? (
                <PageMeta page={page} withoutTitleSuffix onChange={onMetadataChange} />
            ) : null}
            <Ad slot="top" alwaysRender />
            <div className={styles.inner}>
                <ArticlesGrid
                    url={generateUrl('home.latest.json')}
                    items={latestArticles}
                    moreClassName={styles.more}
                />
                <div className={styles.more}>
                    <More href={articlesSection.url} />
                </div>
            </div>
        </main>
    );
};

HomeUniversitiesPage.propTypes = propTypes;
HomeUniversitiesPage.defaultProps = defaultProps;

export default withSite(HomeUniversitiesPage);
