/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { usePage } from '../../contexts/PageContext';
import { useUrlGenerator } from '@folklore/routes';
import { useTrackOnClickLink } from '../../hooks/useTracking';
import Link from '../partials/Link';
import BurgerButton from '../buttons/Burger';
import UniversitiesGuide from '../icons/logos/UniversitiesGuide';

import * as AppPropTypes from '../../lib/PropTypes';
import styles from '../../../styles/headers/header-universities.module.scss';

const propTypes = {
    sticky: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    headerRef: AppPropTypes.ref,
};

const defaultProps = {
    sticky: false,
    className: null,
    headerRef: null,
};

function UniversityHeader({ sticky, toggleMenu, className, headerRef }) {
    const generateUrl = useUrlGenerator();
    const { pageType = null, university = null } = usePage();
    const textColor =
        pageType === 'section' && university !== null && !sticky ? '#f0f0eb' : '#3399FF';
    const onClickLogo = useTrackOnClickLink('click_header_logo');
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.sticky]: sticky,
                    [className]: className !== null,
                },
            ])}
            ref={headerRef}
        >
            <div className={styles.bar}>
                <div className={styles.inner}>
                    <div className={classNames([styles.side, styles.left])} />
                    <div className={styles.center}>
                        <Link
                            href={generateUrl('home')}
                            className={styles.link}
                            onClick={onClickLogo}
                        >
                            <UniversitiesGuide className={styles.logo} color={textColor} />
                        </Link>
                    </div>
                    <div className={classNames([styles.side, styles.right])}>
                        <BurgerButton
                            className={styles.button}
                            color={textColor}
                            onClick={toggleMenu}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

UniversityHeader.propTypes = propTypes;
UniversityHeader.defaultProps = defaultProps;

export default UniversityHeader;
