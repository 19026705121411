import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    expression: PropTypes.oneOf(['tongueOut', 'smile', 'openSmile', 'shock', 'openFrown', 'frown']),
    className: PropTypes.string,
};

const defaultProps = {
    expression: null,
    className: null,
};

const Mouth = ({ className, expression }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="930px"
        height="426.1px"
        viewBox="0 0 930 426.1"
        xmlSpace="preserve"
        className={className}
    >
        {expression === 'tongueOut' ? (
            <path
                d="M881.9,9.3c-6.4-5.2-16-4.4-21.4,1.8C645.9,254.5,211.3,270.7,23,162.7c-7.2-4.1-16.6-1.9-20.9,5
        	c-4.3,6.9-2,15.8,5.2,20c78.3,44.9,213.2,82.3,331.9,82.3c41.5,0,84.2-2.9,127.2-8.9c40.1-5.6,77.5-14.4,112.8-25.7
        	c36.1,72,74.6,114.3,117.4,128.9c11.2,3.8,22.6,5.7,34.3,5.7c18.1,0,36.6-4.6,55.6-13.7c21.4-10.3,35.9-28.7,42-53.1
        	c13.2-52.8-14.9-127.9-49-174.5c38.1-29.8,72.5-63.3,104-99C889.1,23.6,888.3,14.4,881.9,9.3z M799.1,296.5
        	c-4.1,16.3-12.6,27.3-26.1,33.9c-23.8,11.5-45.3,13.6-66,6.5c-34-11.6-67.2-49.2-98.9-111.5c20.9-7.8,40.9-16.5,60.2-26.1
        	c14.1,17.2,40.4,50.3,56.4,76.7c2.8,4.7,7.9,7.3,13.2,7.3c2.6,0,5.2-0.6,7.6-2c7.3-4,9.8-12.9,5.6-19.9
        	c-15.9-26.3-40.6-57.9-55.8-76.4c21.1-11.8,41.3-24.5,60.5-38.3C785.8,188.6,809.7,253.9,799.1,296.5z"
            />
        ) : null}
        {expression === 'smile' ? (
            <path
                d="M20.9,141.9c69.6,26.8,234.6,38.5,454.1,24.6c212-13.4,337.6-94.1,383.8-151.8c23.9-29.9,30.1-8.8,23.5,16.5
                    C853.5,141,765.5,382.8,475.1,422.4C242.9,454.1,69.2,274.4,9.5,179.5C-2.7,160.1-7.1,131.1,20.9,141.9z"
            />
        ) : null}
        {expression === 'openSmile' ? (
            <path
                d="M768.8,151.5c13.8,78.5-97.3,204.8-256.7,232.8c-159.4,28-306.9-52.9-320.7-131.4
        	c-13.8-78.5,111.6-64.1,271-92.1S755.1,73,768.8,151.5z"
            />
        ) : null}
        {expression === 'shock' ? (
            <path
                d="M508.5,345.5c53.9-10,106.9-1.4,152.5,21.3c17-34.2,23.4-73.9,16-114.4
            c-18.4-99.1-113.6-164.6-212.8-146.3S278.7,219.8,297,318.9c7.5,40.4,27.8,75.2,55.8,101.1C387.3,382.5,454.6,355.5,508.5,345.5z"
            />
        ) : null}
        {expression === 'openFrown' ? (
            <path
                d="M239.9,361.8c193.8-96.8,287.7-68.6,347.3-46.6c64.9,23.9,127.2,78.1,149.1-15.9C765,176.4,621,85.2,506.8,102.6
        			c-102.7,15.7-229.2,80.6-292.6,237.1C197.3,381.3,221.9,370.8,239.9,361.8z"
            />
        ) : null}
        {expression === 'frown' ? (
            <path
                d="M55.2,311.4c-3.5,0-7.1-1.2-10-3.7c-6.4-5.5-7-15.1-1.5-21.5c85.5-98.7,255.1-205.1,373.8-195.7
        	c51.6,4.1,92.5,28.3,118.2,69.9c44.9,72.5,95.7,55.8,190.8,3.2c70.6-39.1,143.6-79.6,198.5-28.4c6.2,5.7,6.5,15.4,0.8,21.5
        	c-5.7,6.2-15.4,6.5-21.5,0.8c-38.7-36.1-99.1-2.6-163,32.8c-79.4,44-200.4,121.2-241.6,26.8C484,181,447.1,164.9,405,161.5
        	C297.2,153,145.5,215.3,66.7,306.2C63.7,309.7,59.5,311.4,55.2,311.4z"
            />
        ) : null}
    </svg>
);

Mouth.propTypes = propTypes;
Mouth.defaultProps = defaultProps;

export default Mouth;
