/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'wouter';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import useSwipe from '../../hooks/useSwipe';
import { useUrlGenerator } from '@folklore/routes';
import { useTrackOnClickLink } from '../../hooks/useTracking';

import UrbaniaCrackedIcon from '../icons/logos/UrbaniaCracked';
import GuideIcon from '../icons/logos/UniversitiesGuideCircle';
import BurgerButton from '../buttons/Burger';
import Search from '../forms/Search';
// import SearchOpenButton from '../buttons/Search';
import Menu from './Menu';

import styles from '../../../styles/menus/main-menu-universities.module.scss';

const propTypes = {
    sectionsMenu: AppPropTypes.menu,
    pagesMenus: PropTypes.arrayOf(AppPropTypes.menu),
    opened: PropTypes.bool.isRequired,
    color: AppPropTypes.color,
    closeMenu: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    backgroundColor: AppPropTypes.color,
};

const defaultProps = {
    sectionsMenu: [],
    pagesMenus: [],
    color: '#000',
    backgroundColor: '#39f',
    className: null,
};

export const MainMenuUniversities = ({
    sectionsMenu,
    pagesMenus,
    backgroundColor,
    opened,
    color,
    closeMenu,
    toggleMenu,
    className,
}) => {
    const refContainer = useRef(null);
    const generateUrl = useUrlGenerator();
    const [,setLocation] = useLocation();
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (opened) {
            disableBodyScroll(refContainer.current);
        } else {
            enableBodyScroll(refContainer.current);
        }
        return () => {
            enableBodyScroll(refContainer.current);
        };
    }, [opened]);

    const onSearchSubmit = useCallback(
        (searchingQuery) => {
            setLocation(`${generateUrl('search')}?q=${encodeURIComponent(searchingQuery)}`);
            closeMenu();
        },
        [closeMenu, setLocation, generateUrl],
    );

    const onMenuClick = useCallback(() => toggleMenu(), [toggleMenu]);

    const onSwipeRight = useCallback(() => {
        if (opened) {
            closeMenu();
        }
    }, [opened, closeMenu]);
    const bindSwipe = useSwipe({ onSwipeRight });
    const onClickMenuItem = useTrackOnClickLink('click_menu_item');

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [className]: className !== null,
                },
            ])}
            style={{ backgroundColor, color }}
            {...bindSwipe()}
        >
            <div className={styles.top}>
                <div className={styles.bar}>
                    <UrbaniaCrackedIcon className={styles.logo} />
                    <BurgerButton
                        className={styles.burger}
                        color={color}
                        menuOpened={opened}
                        onClick={onMenuClick}
                    />
                </div>
            </div>

            <div className={styles.search}>
                <Search
                    className={styles.form}
                    inputRef={searchInputRef}
                    onSubmit={onSearchSubmit}
                    color={color}
                />
            </div>

            <div className={styles.menus}>
                <div className={styles.mainMenu}>
                    <h4>
                        <FormattedMessage defaultMessage="Universités" description="Menu title" />
                    </h4>
                    <Menu
                        items={sectionsMenu.items}
                        itemClassName={styles.item}
                        linkClassName={styles.link}
                        onClickItem={onClickMenuItem}
                    />
                </div>
                <div className={styles.linksMenu}>
                    {pagesMenus.map(({ items }, index) => (
                        <div key={`list-${index}`} className={styles.menu}>
                            <Menu
                                items={items}
                                linkClassName={styles.link}
                                labelClassName={styles.label}
                                hasSubMenuClassName={styles.hasSubmenu}
                                subMenuClassName={styles.subMenu}
                                itemClassName={styles.item}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.background}>
                <GuideIcon color={color} backgroundColor="transparent" className={styles.icon} />
            </div>
        </div>
    );
};

MainMenuUniversities.propTypes = propTypes;
MainMenuUniversities.defaultProps = defaultProps;

export default MainMenuUniversities;
