/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../../../styles/partials/universities/score-item.module.scss';
import * as defaultImages from '../../icons/universities/scores';

const propTypes = {
    score: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    }).isRequired,
    images: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
};

const defaultProps = {
    images: defaultImages,
    className: null,
};

function ScoreItem({ score: { id, name, value }, images, className }) {
    const image = images[id] || null;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.shape}>
                <div className={styles.inner}>
                    <div className={styles.title}>{name}</div>
                    <div
                        className={styles.icon}
                        style={{
                            backgroundImage: image !== null ? `url("${image}")` : null,
                        }}
                    />
                    <div className={styles.value}>{value || 'N/A'}</div>
                </div>
            </div>
        </div>
    );
};

ScoreItem.propTypes = propTypes;
ScoreItem.defaultProps = defaultProps;

export default ScoreItem;
