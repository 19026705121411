/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../../lib/PropTypes';
import Link from '../Link';
import BadgeWithScore from './BadgeWithScore';
import ArrowUpIcon from '../../icons/universities/ArrowUp';
import ArrowDownIcon from '../../icons/universities/ArrowDown';

import styles from '../../../../styles/partials/universities/ranking.module.scss';

const propTypes = {
    items: AppPropTypes.universities,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    className: null,
};

export const Ranking = ({ items, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <ul className={styles.items}>
            {items.map((item, index) => {
                const { id, primaryColor, url, name, score, difference } = item;
                const rank = index + 1;
                return (
                    <li className={styles.item} key={`item-${id}`}>
                        <Link href={url} className={styles.link}>
                            <span className={styles.rank}>
                                <FormattedMessage
                                    defaultMessage="{rank, plural, =1 {#<sup>er</sup>} other {#<sup>e</sup>}}"
                                    description="Count"
                                    values={{
                                        rank,
                                    }}
                                />
                            </span>

                            <span className={styles.schoolLogo}>
                                <BadgeWithScore
                                    university={item}
                                    score={score}
                                    className={styles.icon}
                                />
                            </span>

                            <span className={styles.schoolName} style={{ color: primaryColor }}>
                                {name}
                            </span>

                            <span className={styles.arrow}>
                                {difference > 0 ? (
                                    <ArrowUpIcon className={styles.arrowIcon} />
                                ) : null}
                                {difference < 0 ? (
                                    <ArrowDownIcon className={styles.arrowIcon} />
                                ) : null}
                            </span>
                        </Link>
                    </li>
                );
            })}
        </ul>
    </div>
);

Ranking.propTypes = propTypes;
Ranking.defaultProps = defaultProps;

export default Ranking;
