/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import TextBackground from '../partials/TextBackground';
import Header from '../partials/universities/Header';
import ScoresRow from '../partials/universities/ScoresRow';
import SectionHeaderNormal from './SectionHeaderNormal';

import styles from '../../../styles/headers/section-header-universities.module.scss';

const propTypes = {
    section: AppPropTypes.section.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    className: null,
};

function SectionHeaderUniversities({ section, title, className }) {
    const {
        ref: contentRef,
        entry: { contentRect = null },
    } = useResizeObserver();

    const headerHeight = useMemo(
        () => (contentRect !== null ? contentRect.height + 100 : 0),
        [contentRect],
    );

    const { university = null } = section;

    const [score, setScore] = useState(0);
    const onScoreUpdate = useCallback((value) => setScore(value), [setScore]);
    const numberFormatter = useMemo(() => new Intl.NumberFormat('fr-CA'), []);
    const scores = useMemo(
        () =>
            university !== null
                ? university.scores.map((it) =>
                      it.id === 'engagement'
                          ? {
                                ...it,
                                value:
                                    it.value !== null
                                        ? numberFormatter.format(
                                              parseInt(it.value.replace(/[^0-9]+/, ''), 10) + score,
                                          )
                                        : 0,
                            }
                          : it,
                  )
                : [],
        [university, score],
    );

    return university !== null ? (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.background}>
                <TextBackground
                    text={university.shortName}
                    color={university.primaryColor}
                    height={headerHeight}
                    withFade
                    className={styles.text}
                />
            </div>
            <div className={styles.content} ref={contentRef}>
                <div className={styles.top}>
                    <Header
                        university={university}
                        className={styles.header}
                        onScoreUpdate={onScoreUpdate}
                    />
                </div>
                <div className={styles.bottom}>
                    <ScoresRow items={scores} indexUrl={university.scoresUrl} />
                </div>
            </div>
        </div>
    ) : (
        <SectionHeaderNormal section={section} title={title} className={className} />
    );
}

SectionHeaderUniversities.propTypes = propTypes;
SectionHeaderUniversities.defaultProps = defaultProps;

export default SectionHeaderUniversities;
