import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../../lib/PropTypes';

const directionTransform = {
    top: null,
    bottom: 'rotate(180) translate(-30, -30)',
};

const propTypes = {
    direction: AppPropTypes.arrowDirection,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    direction: 'top',
    color: 'currentColor',
    className: null,
};

const Arrow = ({ direction, color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        xmlSpace="preserve"
        className={className}
    >
        <g transform={directionTransform[direction]}>
            <path
                fill={color}
                d="M18.6,1.3c-0.5-0.5-1.4-1-2-1.3h-3c-0.6,0.2-1.5,0.8-2,1.3L0.3,12.7c-0.5,0.5-0.3,0.9,0.4,0.9h8.1
    	c0.7,0,1.2,0.5,1.2,1.2v10.9c0,0.7,0.5,1.2,1.2,1.2h8.1c0.7,0,1.2-0.5,1.2-1.2V14.7c0-0.7,0.5-1.2,1.2-1.2h8.1
    	c0.7,0,0.8-0.4,0.4-0.9L18.6,1.3z"
            />
        </g>
    </svg>
);

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
