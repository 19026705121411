/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';

import Ad from '../partials/Ad';
import Blocks from '../blocks/Blocks';
import AdSidebars from '../partials/AdSidebars';
import PageMeta from '../meta/PageMeta';
import PageHeader from '../partials/PageHeader';
import Ranking from '../partials/universities/Ranking';

import styles from '../../../styles/pages/page-leaderboard.module.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    isDisabled: PropTypes.bool,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    isDisabled: false,
    onMetadataChange: null,
};

function LeaderboardPage({ page, isDisabled, onMetadataChange }) {
    const { leaderboard = [], blocks = [] } = page;
    return (
        <main className={styles.container}>
            {!isDisabled ? <PageMeta page={page} onChange={onMetadataChange} /> : null}
            <Ad slot="top" />
            <PageHeader page={page} className={styles.header} />
            <AdSidebars isOnlyRight>
                <div className={styles.inner}>
                    <div className={styles.description}>
                        <Blocks blocks={blocks} />
                    </div>
                    <Ranking items={leaderboard} />
                </div>
            </AdSidebars>
        </main>
    );
};

LeaderboardPage.propTypes = propTypes;
LeaderboardPage.defaultProps = defaultProps;

export default LeaderboardPage;
