/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';

import Ad from '../partials/Ad';
import PageMeta from '../meta/PageMeta';
import Header from '../partials/universities/Header';
import ScoreCard from '../partials/universities/ScoreCard';

import styles from '../../../styles/pages/page-score.module.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    isDisabled: PropTypes.bool,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    isDisabled: false,
    onMetadataChange: null,
};

function ScorePage({ page, isDisabled, onMetadataChange }) {
    const { university } = page;
    const { scores = [] } = university;
    return (
        <main className={styles.container}>
            {!isDisabled ? <PageMeta page={page} onChange={onMetadataChange} /> : null}
            <Ad slot="top" />
            <div className={styles.header} style={{ color: university.primaryColor }}>
                <Header university={university} selectLinkToScore />
            </div>
            <div className={styles.inner}>
                <div className={styles.items}>
                    {scores.map(it => (
                        <div className={styles.item}>
                            <ScoreCard item={it} key={`score-${it.id}`} className={styles.card} />
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
};

ScorePage.propTypes = propTypes;
ScorePage.defaultProps = defaultProps;

export default ScorePage;
