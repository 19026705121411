import { useResizeObserver, useCounter } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../../lib/PropTypes';

import BadgeIcon from '../../icons/universities/badges/Badge';
import Mouth from './Mouth';
import Score from './Score';

import styles from '../../../../styles/partials/universities/badge-with-score.module.scss';

const propTypes = {
    university: AppPropTypes.university.isRequired,
    scoreToExpression: PropTypes.arrayOf(
        PropTypes.shape({
            expression: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
        }),
    ),
    withoutMouth: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    scoreToExpression: [
        {
            expression: 'tongueOut',
            max: 200,
            min: 130,
        },
        {
            expression: 'smile',
            max: 129,
            min: 110,
        },
        {
            expression: 'openSmile',
            max: 109,
            min: 90,
        },
        {
            expression: 'shock',
            max: 89,
            min: 70,
        },
        {
            expression: 'openFrown',
            max: 69,
            min: 50,
        },
        {
            expression: 'frown',
            max: 49,
            min: 0,
        },
    ],
    withoutMouth: false,
    className: null,
};

function BadgeWithScore({ university, scoreToExpression, withoutMouth, className }) {
    const { id, score, secondaryColor: color, primaryColor: backgroundColor } = university;

    const {
        ref: containerRef,
        entry: { contentRect = null },
    } = useResizeObserver();

    const fontSize = useMemo(
        () => (contentRect !== null ? contentRect.height * 0.38 : 0),
        [contentRect],
    );

    const expression = useMemo(
        () =>
            scoreToExpression.reduce((finalExpression, { expression: value, min, max }) => {
                if (score <= max && score >= min) {
                    return value;
                }
                return finalExpression;
            }, null),
        [score, scoreToExpression],
    );

    const counterValue = useCounter(score, {
        disabled: true,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withoutMouth]: withoutMouth,
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
            style={{
                fontSize,
            }}
        >
            <div className={styles.shape}>
                <div className={styles.backgroundCircle} style={{ background: backgroundColor }} />
                <Score
                    className={styles.score}
                    value={counterValue}
                    color={color}
                    strokeColor={backgroundColor}
                />
                {!withoutMouth ? (
                    <div className={styles.mouth}>
                        <Mouth className={styles.icon} expression={expression} />
                    </div>
                ) : null}
                <BadgeIcon university={id} className={styles.outerRing} color={color} />
            </div>
        </div>
    );
}

BadgeWithScore.propTypes = propTypes;
BadgeWithScore.defaultProps = defaultProps;

export default BadgeWithScore;
