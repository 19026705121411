import React from 'react';
import PropTypes from 'prop-types';
import * as AppPropTypes from '../../../lib/PropTypes';

const propTypes = {
    arrowColor: AppPropTypes.color,
    className: PropTypes.string,
};

const defaultProps = {
    arrowColor: '#96d8b7',
    className: null,
};

const ArrowUpIcon = ({ arrowColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24.1px"
        height="21.1px"
        viewBox="0 0 24.1 21.1"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={arrowColor}
            d="M14.1,0.8c-1.1-1.1-2.9-1.1-4,0L0,10.9h8v10.2h8V10.9h8L14.1,0.8z"
        />
    </svg>
);

ArrowUpIcon.propTypes = propTypes;
ArrowUpIcon.defaultProps = defaultProps;

export default ArrowUpIcon;
