/* eslint-disable react/no-danger */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Arrow from '../../icons/universities/UniversityArrow';

import styles from '../../../../styles/partials/universities/score-card.module.scss';
import * as defaultImages from '../../icons/universities/scores';

const propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        value: PropTypes.string,
        difference: PropTypes.number,
    }).isRequired,
    images: PropTypes.objectOf(PropTypes.string),
    arrowColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    images: defaultImages,
    arrowColor: '#A5DDC1',
    className: null,
};

const ScoreCard = ({
    item: { id, name, description, value, difference = 0 },
    images,
    arrowColor,
    className,
}) => {
    const image = images[id] || null;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.left}>
                    <img src={image} className={styles.icon} alt={name} />
                    <div className={styles.value}>{value || 'N/A'}</div>
                </div>
                <div className={styles.center}>
                    <div className={styles.title}>{name}</div>
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
                <div className={styles.right}>
                    {difference !== 0 ? (
                        <Arrow
                            direction={difference > 0 ? 'top' : 'bottom'}
                            className={styles.arrow}
                            color={arrowColor}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

ScoreCard.propTypes = propTypes;
ScoreCard.defaultProps = defaultProps;

export default ScoreCard;
