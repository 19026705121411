/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../../lib/PropTypes';

import styles from '../../../../styles/partials/universities/score.module.scss';

const propTypes = {
    color: AppPropTypes.color,
    strokeColor: AppPropTypes.color,
    value: PropTypes.number,
    withoutShadow: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: null,
    strokeColor: null,
    value: 100,
    withoutShadow: false,
    className: null,
};

function Score({ color, strokeColor, value, withoutShadow, className }) {
    const digits = `${value}`.split('');
    const digitsCount = digits.length;
    const lastIndex = digitsCount - 1;
    const textShadow =
        strokeColor !== null
            ? [
                  `-0.05em -0.05em 0${strokeColor}`,
                  `0.05em -0.05em 0${strokeColor}`,
                  `-0.05em 0.05em 0${strokeColor}`,
                  `0.05em 0.05em 0${strokeColor}`,
              ].join(', ')
            : null;
    const digitStyle = {
        textShadow,
        webkitTextShadow: textShadow,
        mozTextShadow: textShadow,
        msTextShadow: textShadow,
    };
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ color }}
        >
            <div className={styles.textContainer}>
                <div className={styles.value}>
                    {digits.map((digit, index) => (
                        <span
                            className={classNames([
                                styles.digit,
                                {
                                    [styles.right]:
                                        index === 0 && digit === '1' && digitsCount === 3,
                                },
                                {
                                    [styles.left]:
                                        index === lastIndex && digit === '1' && digitsCount === 3,
                                },
                            ])}
                            style={digitStyle}
                            key={`digit-${index}`}
                        >
                            {digit}
                        </span>
                    ))}
                </div>
                {!withoutShadow ? (
                    <div className={styles.scoreShadow} style={{ color: '#000' }}>
                        {digits.map((digit, index) => (
                            <span
                                className={classNames([
                                    styles.digit,
                                    {
                                        [styles.right]: index === 0 && digit === '1' && digitsCount === 3,
                                    },
                                    {
                                        [styles.left]: index === lastIndex && digit === '1' && digitsCount === 3,
                                    },
                                ])}
                                key={`digit-shadow-${index}`}
                            >
                                {digit}
                            </span>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Score.propTypes = propTypes;
Score.defaultProps = defaultProps;

export default Score;
