import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    lineColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#F0F0EB',
    lineColor: '#FFFFFF',
    className: null,
};

const ScoresRow = ({ color, lineColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="1101.1px"
        height="121.1px"
        viewBox="0 0 1101.1 121.1"
        xmlSpace="preserve"
        className={className}
    >
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="477.5,0.5 460.9,0.5 0.6,30.5 100.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="494.2,0.5 477.5,0.5 100.6,30.5 200.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="510.9,0.5 494.2,0.5 200.6,30.5 300.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="527.5,0.5 510.9,0.5 300.6,30.5 400.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="544.2,0.5 527.5,0.5 400.6,30.5 500.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="560.9,0.5 544.2,0.5 500.6,30.5 600.6,30.5 "
        />
        <rect
            x="0.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="100.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="200.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="300.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="400.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="500.6"
            y="30.5"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="627.5,0.5 644.2,0.5 1100.6,30.5 1000.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="610.9,0.5 627.5,0.5 1000.6,30.5 900.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="594.2,0.5 610.9,0.5 900.6,30.5 800.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="577.5,0.5 594.2,0.5 800.6,30.5 700.6,30.5 "
        />
        <polygon
            fill={color}
            stroke={lineColor}
            strokeMiterlimit="10"
            points="560.9,0.5 577.5,0.5 700.6,30.5 600.6,30.5 "
        />
        <rect
            x="1000.6"
            y="30.5"
            transform="matrix(-1 -3.470658e-11 3.470658e-11 -1 2101.1338 151)"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="900.6"
            y="30.5"
            transform="matrix(-1 -3.459734e-11 3.459734e-11 -1 1901.1339 151)"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="800.6"
            y="30.5"
            transform="matrix(-1 -3.470658e-11 3.470658e-11 -1 1701.1339 151)"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="700.6"
            y="30.5"
            transform="matrix(-1 -3.459734e-11 3.459734e-11 -1 1501.1339 151)"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
        <rect
            x="600.6"
            y="30.5"
            transform="matrix(-1 -3.459734e-11 3.459734e-11 -1 1301.1339 151)"
            fill={color}
            stroke={lineColor}
            strokeWidth="1.1339"
            strokeMiterlimit="10"
            width="100"
            height="90"
        />
    </svg>
);

ScoresRow.propTypes = propTypes;
ScoresRow.defaultProps = defaultProps;

export default ScoresRow;
