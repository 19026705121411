import React from 'react';
import PropTypes from 'prop-types';
import * as AppPropTypes from '../../../lib/PropTypes';

const propTypes = {
    arrowColor: AppPropTypes.color,
    className: PropTypes.string,
};

const defaultProps = {
    arrowColor: '#ffcbcb',
    className: null,
};

const ArrowDownIcon = ({ arrowColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24.1px"
        height="21.1px"
        viewBox="0 0 24.1 21.1"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={arrowColor}
            d="M10.1,20.3c1.1,1.1,2.9,1.1,4,0l10.1-10.1h-8V0H8v10.2H0L10.1,20.3z"
        />
    </svg>
);

ArrowDownIcon.propTypes = propTypes;
ArrowDownIcon.defaultProps = defaultProps;

export default ArrowDownIcon;
