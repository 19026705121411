/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../../lib/PropTypes';
import UniversitiesSelect from './Select';
import BadgeWithScore from './BadgeWithScore';
import Link from '../Link';

import styles from '../../../../styles/partials/universities/header.module.scss';

const propTypes = {
    university: AppPropTypes.university.isRequired,
    selectLinkToScore: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    selectLinkToScore: false,
    className: null,
};

function UniversitiesHeader({ university, selectLinkToScore, className }) {
    const { name, url } = university;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={classNames([styles.side, styles.left])}>
                <div className={styles.universityName}>
                    <Link href={url}>{name}</Link>
                </div>
            </div>
            <div className={styles.center}>
                <div className={styles.rank}>
                    &nbsp;
                </div>
                <div className={styles.score}>
                    <BadgeWithScore university={university} className={styles.badge} />
                </div>
                <div className={styles.arrows}>
                    &nbsp;
                </div>
            </div>
            <div className={classNames([styles.side, styles.right])}>
                <UniversitiesSelect className={styles.select} linkToScore={selectLinkToScore} />
            </div>
        </div>
    );
};

UniversitiesHeader.propTypes = propTypes;
UniversitiesHeader.defaultProps = defaultProps;

export default UniversitiesHeader;
