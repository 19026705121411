/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ScoreItem from './ScoreItem';
import ScoresRowIcon from '../../icons/universities/ScoresRow';
import Link from '../Link';

import styles from '../../../../styles/partials/universities/scores-row.module.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    indexUrl: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    indexUrl: null,
    className: null,
};

const ScoresRow = ({ items, indexUrl, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.inner}>
            <ScoresRowIcon className={styles.background} />
            <div className={styles.items}>
                {items.map((item, index) => (
                    <div key={`item${index}`} className={styles.item}>
                        {indexUrl !== null ? (
                            <Link href={indexUrl}>
                                <ScoreItem score={item} />
                            </Link>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

ScoresRow.propTypes = propTypes;
ScoresRow.defaultProps = defaultProps;

export default ScoresRow;
